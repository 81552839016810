
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

body {
  font-size: 14px;
  font-family: 'Source Sans Pro', Roboto;
  color: rgb(3,45,96);
  width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

a:visited, a:link {
  color: rgb(3,45,96)
}