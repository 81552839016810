.okr-tile-icon::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - var(--bord));
    height: calc(100% - var(--bord));
    background: white;
    border-radius: inherit;
}

.calendar-day {
    overflow-y: hidden;
}

.calendar-day:hover {
    overflow-y: scroll;
}

.calendar-day::-webkit-scrollbar {
    width: 4px;
}

.calendar-day::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.calendar-day::-webkit-scrollbar-thumb {
    background-color: rgb(169, 169, 169);
    border-radius: 2px;
}

.calendar-day-text {
    color: rgba(0,0,0,.8)
}

#calendar-container {
    height: 100%;
    min-width: 800px;
}

@media (max-width: 1250px) {
    .day-0, .day-6 {
        display: none
    }
}