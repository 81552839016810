.completion-row {
    display: flex;
    align-items: center;
    margin: 0 8px;
}

.completion-container {
    margin: 0 8px;
    background: rgba(0,0,0,.025);
    padding: 8px;
    overflow-y: scroll;
}

.integration-container {
    display: flex;
    flex-direction: column;
}

.MuiSlider-rail {
    height: 12px !important;
    border-radius: 2px !important;
}

.MuiSlider-track {
    height: 12px !important;
    border-radius: 2px !important;
}

.MuiSlider-thumb {
    background-color: #fff !important;
    border: solid 2px #1976d2;
    height: 24px !important;
    width: 24px !important;
}

.jira-config-section {
    background: rgba(0,0,0,.025);
    border-radius: 8px;
    padding: 4px;
    margin: 8px;
}

.jira-section-label {
    font-weight: 600;
    margin: 4px 8px;
}