.splash-section::before {
    position: absolute;
    background: linear-gradient(-45deg, #e37452, #e63b7e, #24a7d5, #24c5ab);
    transform: rotate(-10deg);
    height: 100%;
    width: 150%;
    background-size: 100% 160%;
    -webkit-animation: AnimationName 30s ease infinite;
    -moz-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;
    content: "";
    top: -70vh;
    z-index: -10;
    /* C2B3D0 */
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.decoration::before {
    position: absolute;
    background: linear-gradient(-45deg, #d9e8ee, #c52471);
    transform: rotate(-10deg);
    height: 600px;
    width: 200%;
    background-size: 100% 160%;
    left: -40vw;
    content: "";
}

.splash-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10% 0px;
    width: 100vw;
    overflow: hidden;
    flex-direction: column;
}

.splash-section {
    max-height: 50vh;
}

.splash-section2 {
    height: 50vh;
    background: #fbfbfb;
    width: 100vw;
}

.image-box {
    height: 33vh;
    box-shadow: inset 0 1px 1px 0 hsla(0,0%,100%,.1), 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3);
}

.splash-item {
    max-width: 1200px
}

@media (max-width: 700px) {
    .image-box, img {
        max-width: 80vw;
    }
    .splash-section, .splash-item {
        flex-direction: column !important;
        max-height: none;
        height: auto !important;
    }
    .splash-section2 {
        height: auto;
    }
    .decoration::before, .splash-section::before {
        display: none;
    }
    #alert-image {
        display: none;
    }
    #si-2 {
        flex-direction: column-reverse !important;
        margin-top: 20px;
        height: auto;
    }
}