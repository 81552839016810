.board-container {
    height: 100%;
    padding-bottom: 60px;
    overflow-y: auto;
}


.board-container::-webkit-scrollbar {
    width: 4px;
}

.board-container::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background:transparent;
}

.board-container::-webkit-scrollbar-thumb {
    background-color: rgb(169, 169, 169);
    border-radius: 2px;
}


.board-group {
    display: flex;
    flex-direction: column;
    border: solid 1px #bbb;
    border-radius: 8px;
    padding: 8px;
    margin: 12px 0;
}

.board-options {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #bbb;
    margin-bottom: 8px;
}

.okr-container {
    display: flex;
    justify-content: space-between;
}

.board-name {
    font-weight: 600;
}

.okr-table-header {
    display: flex;
    justify-content: space-between;
}

.okr-table-head, .okr-item {
    width: 25%;
}

.okr-item {
    border: solid 1px #bbb;
    padding: 4px;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.okr-completion span {
    margin: 0 16px 0px 8px;
}

.okr-name.okr-item {
    color: rgb(30,85,136)
}

.okr-table-head {
    font-weight: 600;
}