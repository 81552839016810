.contact-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10% 0px;
    width: 100vw;
    overflow: hidden;
    height: 400px;
}

.contact-left {
    width: 40%;
}

.contact-title {
    font-size: 56px;
    line-height: 56px;
}

.contact-list-head {
    margin-top: 24px;
    margin-left: 4px;
    font-weight: 800;
}

.contact-right {
    display: flex;
    flex-direction: column;
    padding: 12px;
    width: 40%;
}

.contact-right div {
    margin: 4px 0;
}

#decoration::before {
    
    background: #fefadf;
    content: "";
    position: absolute;
    height: 750px;
    top: 0;
    width: 100%;
    z-index: -2;
    left: 0;
}

#decoration::after {
    position: absolute;
    background: linear-gradient(-45deg, #24d5ba, #6694f3);
    transform: rotate(-10deg);
    height: 100px;
    width: 150%;
    background-size: 100% 160%;
    left: -40vw;
    top: 60vh;
    content: "";
    z-index: -1;
}