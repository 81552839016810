.okr-node .okr-node-controls {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 116px;
    width: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.okr-node-controls button {
    color: rgba(50,50,50,.8);
}

.okr-node:hover .okr-node-controls {
    visibility: visible;
}

.okr-node-controls {
    top: 0;
    right: 0;
}

.okr-node {
    padding: 12px;
    display: flex;
    border: solid 1px #0042d048;
    border-radius: 4px;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    height: 100%;
    width: 260px;
    background-color: #f6fdff;
}

.react-flow__node-okr {
    width: 260px !important;
}

.okr-title {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 8px;
}

.okr-handle {
    border-radius: 24px !important;
    height: 20px !important;
    width: 24px !important;
}

.okr-node-title {    
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
}

.okr-node-avatar {    
    position: absolute;
    top: -12px;
    right: -8px;
    background: #eee;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    padding: 4px;
    color: #555;
    font-size: 12px;
}