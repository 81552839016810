.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
    position: fixed;
    z-index: 1000;
  }
  
aside .description {
    margin-bottom: 10px;
  }
  
.dndnode {
    height: 64px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
  }
  
.dndnode.input {
    border-color: #0041d0;
  }
  
.dndnode.output {
    border-color: #ff0072;
  }
  
.reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
.selectall {
    margin-top: 10px;
  }
  
@media screen and (min-width: 768px) {

aside {
    width: 20%;
    max-width: 250px;
}
}