.group-node {
    z-index: -100;
    display: flex;
    border: solid 1px #999;
    border-radius: 16px;
    flex-direction: column;
    background: aliceblue;
    width: 100%;
    height: 100%;
}
.group-node-name {
    position: absolute;
    top: -12px;
    left: 40%;
    background: #def;
}

.react-flow__node-group {
    padding: 0 !important;
    border: none;
}

.react-flow__resize-control.handle {
    height: 8px !important;
    width: 8px !important;
}