.okr-comment {
    display: flex;
    padding: 8px
}

.okr-comment-right {
    margin: 0 8px;
}

.okr-comment-author {
    font-weight: 600;
}

.okr-comment-time {
    color: #bbb;
    margin: 0 8px;
}

.okr-comment-right-top {
    display: flex;
}

.autocomplete-hint {
    font-size: 10px;
    position: relative;
    top: -16px;
    float: right;
}

.MuiSlider-rail {
    height: 12px !important;
    border-radius: 2px !important;
}

.MuiSlider-track {
    height: 12px !important;
    border-radius: 2px !important;
}

.MuiSlider-thumb {
    background-color: #fff !important;
    border: solid 2px #1976d2;
    height: 24px !important;
    width: 24px !important;
}